import { Stack, Flex, Heading, VStack, Box } from "@chakra-ui/react";
import React from "react";

export default function PageHeadingSection({ title, subtitle, imgSrc = null }) {
  return (
    <>
      <Flex w={"full"} h={"30vh"} position={"relative"} bgColor={"#2b2f69"}>
        <Box
          w={"full"}
          h={"full"}
          position={"absolute"}
          bg={"rgba(0,0,0,0.5)"}
          zIndex={"2"}
          top={0}
        ></Box>
        <VStack
          w={"full"}
          justify={"center"}
          px={{ base: 4, md: 8 }}
          position={"relative"}
          zIndex={"3"}
        >
          <Stack maxW={"2xl"} align={"center"} spacing={6} direction={"column"}>
            <Heading
              as={"h1"}
              color={"white"}
              pt={2}
              lineHeight={1.2}
              size={"2xl"}
              textAlign="center"
            >
              {title}
            </Heading>
            <Heading
              as="h2"
              size="md"
              color="white"
              textTransform={"capitalize"}
            >
              {subtitle}
            </Heading>
          </Stack>
        </VStack>
      </Flex>
    </>
  );
}
