import React from "react";
import Layout from "../components/Layout";
import PageHeadingSection from "../components/PageHeadingSection";
import { Box, Container, SimpleGrid, Heading } from "@chakra-ui/react";
import ContactForm from "../components/ContactForm";

export default function ContactUS() {
  return (
    <Layout>
      <PageHeadingSection title={"Contact Us"} />
      <Container maxW={"7xl"} py={5}>
        <Box maxW={"lg"} mx="auto">
          <ContactForm />
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} mt={5} spacing={3}>
          <Box>
            <Heading my={3}>UAE</Heading>
            {
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.564496317924!2d55.29904731501056!3d25.251589383870968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4364567289d9%3A0x7dc526f222a949a9!2sThe%20Burjuman%20Business%20Tower!5e0!3m2!1sen!2sin!4v1639221614577!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                title="uae map"
              ></iframe>
            }
          </Box>
          <Box>
            <Heading my={3}>INDIA</Heading>
            {
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14039.057840932725!2d77.3125635!3d28.3961813!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3726814f9d19a8cc!2sBSCIC%20Certifications%20Private%20Limited!5e0!3m2!1sen!2sin!4v1638617512060!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="india uae"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            }
          </Box>
        </SimpleGrid>
      </Container>
    </Layout>
  );
}
